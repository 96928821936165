import React from "react";

const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));

// Import all components
const StudioCirca = React.lazy(() => import("./pages/StudioCirca/index"));
const ChiSiamo = React.lazy(()=>import("./pages/StudioCirca/ChiSiamo/index"))
const Servizi = React.lazy(()=>import("./pages/StudioCirca/Servizi/index"))
const Contatti = React.lazy(()=>import("./pages/StudioCirca/Contatti/index"))
const CCNL = React.lazy(()=>import("./pages/StudioCirca/CCNL/index"))

const News = React.lazy(()=>import("./pages/StudioCirca/News"))

const routes = [
  {
    path: "/chi_siamo",
    component: ChiSiamo,
    isWithoutLayout: false,
  },
  {
    path: "/servizi",
    component: Servizi,
    isWithoutLayout: false,
  },
  {
    path: "/contatti",
    component: Contatti,
    isWithoutLayout: false,
  },
  {
    path: "/news",
    component: News,
    isWithoutLayout: false,
  },
  {
    path: "/ccnl",
    component: CCNL,
    isWithoutLayout: false,
  },
  /*{
    path: "/staff",
    component: StudioCirca,
    isWithoutLayout: false,
    exact: true,
  },*/
  { path: "/page-error", component: PageError, isWithoutLayout: true },
  {
    path: "/area_riservata",
    component: ()=>{window.location.href = 'https://admin.studiocirca.it'},
  },

  //Index Main
  { path: "/index", component: StudioCirca, isTopbarDark: false },

  //Index root

  { path: "/", component: StudioCirca, isWithoutLayout: false, exact: true },
  { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
